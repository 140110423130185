
body {
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px !important;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}


.ant-btn-link {
    color: #16568a !important;
}

.spinning {
    animation: spinner 1s linear infinite;
    color: #989898;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.bx_prueba {
    position:sticky;
    top: 0;
    z-index:16;
    width:100%;
    background-color:yellow;
    height: 200px
}

.white {
    color: white;

}

.modal-simulador .ant-modal-content {
    /* padding: 0 0 24px !important; */
    padding: 0 !important;
    border-radius: 2.1em !important;
}

.ant-custom-input {
    touch-action: manipulation;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
}

.ant-custom-input:focus,
.ant-custom-input:focus-visible {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
    outline: #40a9ff auto .1px !important;
}
